const site_en = {
  loginPage: {
    title: 'Login account - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Website login, account login, ZKLion platform login',
    description: 'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  registerPage: {
    title: 'Account Registration - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Website registration, account registration,ZKLion platform registration',
    description: 'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  registerProtocolPage: {
    title: 'Registration Agreement | User Term - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Registration Agreement, User Term, ZKLion Platform User Agreement',
    description: 'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  registerActivePage:{ 
    title: 'Account activation - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Account activation, account registration and use',
    description: 'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  forgotPage: {
    title: 'Retrieve password | Password forgotten - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Retrieve password, Password forgotten',
    description: 'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  homePage: {
    title: 'ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Digital currency , PPLNS  , SOLO  , ervice,  ',
    description:'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  workPage: {
    title: 'Machine management | Mine Management - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Machine management, Mine Management',
    description:'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  revenuePage: {
    title: 'Earnings See | Earnings Management - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Earnings See, Earnings Management',
    description:'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  minerPage: {
    title: 'Account setting - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Personal center, account Settings',
    description:'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  safePage: {
    title: 'Personal settings | Security settings - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Personal settings, Account Security settings',
    description:'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  personalPage: {
    title: 'Personal account management | Personal settings - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Personal account management, Personal settings',
    description:'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  },
  newsPage: {
    title: 'Newsfeed - ZKLion  - Secure and efficient digitalcurrency Aleo ',
    keywords: 'Personal account management, Personal settings',
    description:'ZKLion  is a professional, efficient, technology-leading, safe and efficient digital currency supporting PPS and PPLNS two payment modes, with low rates, high interest, excellent quality and excellent service, so that you can mine less trouble and worry!'
  }
}

export default site_en;

<template>
	<div class="page-banner" v-if="isChinaCheck==1 && isChina==true">
		<Container>
		<div v-if="is_mobile==true" style="max-width: 600px;margin: 0px auto;text-align: center;">
			<h2 style="margin-top: 0rem;margin-bottom: 20px; ">温馨提示</h2>
			<p style="margin: 30px;text-align: center; line-height: 2rem;">
				根據您所在國家或地區的法律，您可能無法訪問本網站。如果您有任何疑問，歡迎聯繫我們的客服團隊獲取幫助。
			</p>
			
			<h2 style="margin-top: 50px;margin-bottom: 20px;">Friendly reminder</h2>
			<p style="margin: 30px;text-align: center;">
				According to the laws of your country or region, you may not be able to access this website. If you have any questions, please feel free to contact our customer service team for assistance.
			</p>
		</div>
		
		<div v-else  style="max-width: 600px;margin: 0px auto;text-align: center;">
			<h2 style="margin-top: 0rem;margin-bottom: 30px; font-size: 2.5rem;">温馨提示</h2>
			
			<p style="margin: 30px;text-align: center;font-size: 1.2rem; line-height: 2rem;">
				根據您所在國家或地區的法律，您可能無法訪問本網站。如果您有任何疑問，歡迎聯繫我們的客服團隊獲取幫助。
			</p>
			<h2 style="margin-top: 50px;margin-bottom: 20px;font-size: 2.5rem;">Friendly reminder</h2>
			<p style="margin: 30px;text-align: center;font-size: 1.2rem; line-height: 2rem;">
				According to the laws of your country or region, you may not be able to access this website. If you have any questions, please feel free to contact our customer service team for assistance.
			</p>
		</div>
	
		</Container>
	</div>
    <div v-else-if="isChinaCheck==1" :class="{ mobile: $store.state.ismobile }">
		<Header></Header>
		<div id="app">
		  <router-view v-if="isRouterAlive" />
		</div>
		<Footer></Footer>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import {checkChinaMainland} from '@/api/aleo';


export default {
  components: {
    Header,
    Footer
  },
  name: 'App',
  data() { 
    return {
      isRouterAlive: true,
      is_mobile:false,
	  isChina:false,
	  isChinaCheck:0,
    }
  },
  beforeCreate(){
	const currentPath = window.location.pathname;
	if( currentPath=='/signUp'){
		window.location.href = 'https://oula.network/zh/register?invitation=DKAZEG'; 
	}
  	let $this=this;
  	checkChinaMainland().then(res => {
  		$this.isChinaCheck=1
  		if(res.code==200 && res.data==false)
  		{
  			// $this.isChina=true;
  			$this.isChina=false;
  		}else{
  			// $this.isChina=false;
  			$this.isChina=true;
  		}
  	})
  },
  created() {
    this.isMobile();
  },
  provide() { 
    return {
      reload:this.reload
    }
  },
  methods: {
    reload() { 
      this.isRouterAlive = false;
      this.$nextTick(() => { 
        this.isRouterAlive = true;
      })
    },
    isMobile() {
      let is_mobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
      if (is_mobile) {
        this.is_mobile = true;
        this.$store.commit('setMobile', true);
      } else {
        this.is_mobile = false;
        this.$store.commit('setMobile', false);
      }
    }

  }
}
</script>

import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: localStorage.lang || 'zh',
    hash: { all: 0, ungroup: 0 },
    user: {},
    ismobile:false,
    project: {},
    poolData: {},
    soloData: {},
    basic_option: {
                title: {
                    text: 'Pool Hashrate / Second'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                xAxis: {
                    // type: 'value',
                    symbol: 'arrow',
                    lineStyle: {
                        type: 'dashed'
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    data: []
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLine: { show: true },
                    axisLabel: {
                        formatter: function (num) {
                            let power_3 = Math.pow(10, 3)
                            let power_6=Math.pow(10, 6)
                            if (num >=power_6) {
                                return (num / power_6).toFixed(1) + 'M'
                            } else if (num >= power_3) {
                                return (num / power_3).toFixed(1) + 'K'
                            } else {
                                return num.toString();
                            }
                        }
                    }
                },
                series: [
                    {
                        name: 'data',
                        type: 'line',
                        smooth: true,
                        itemStyle: { //波浪线颜色
                            color: '#0E9CFF'
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                global: false,
                                colorStops: [
                                    {
                                        offset: 0,
                                        // color: 'rgba(88,160,253,1)'
                                        color: 'rgba(36,124,255,0.4)'
                                    },
                                    {
                                        offset: 0.3,
                                        color: 'rgba(36,124,255,0.2)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(255,255,255,0.1)'
                                    }
                                ]
                            }
                        },
                        data: []
                    }
                ]
    },
    inner_basic_option: {
                title: {
                    text: 'Pool Hashrate / Second',
                    textStyle: {
                        color: '#fff'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                xAxis: {
                    // type: 'value',
                    symbol: 'arrow',
                    axisLabel: {
                        fontSize: 12,
                        color: '#F5F5F5'
                    },
                    lineStyle: {
                        color: 'rgba(245,245,245,0.2)'
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(245,245,245,0.2)'
                        }
                    },
                    data:[]
                    // data: ['20230802', '20230803', '20230804', '20230805', '20230806', '20230807']
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        fontSize: 12,
                        color: '#F5F5F5'
                    },
                    lineStyle: {
                        color: 'rgba(245,245,245,0.2)'
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(245,245,245,0.2)'
                        }
                    },
                    axisLine: {
                        show: false,
                        type: 'dashed',
                        color: 'rgba(245,245,245,0.2)'
                    },
                },
                series: [
                    {
                        name: 'data',
                        type: 'line',
                        smooth: true,
                        itemStyle: { //波浪线颜色
                            color: '#0E9CFF'
                            //  color: 'rgba(245,245,245,0.2)'

                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                global: false,
                                colorStops: [
                                    {
                                        offset: 0,
                                        // color: 'rgba(88,160,253,1)'
                                        color: 'rgba(13,154,255,0.4)'
                                    },
                                    {
                                        offset: 0.3,
                                        color: 'rgba(13,154,255,0.2)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(199,232,255,0.1)'
                                    }
                                ]
                            }
                        },
                        data:[]
                        // data: [5, 20, 36, 10, 10, 20]
                    }
                ]
    },
    solo_address: '',
    is_show_notice:true,
  },
  getters: {
    token: (state) => state.user.token,
    name: (state) => state.user.name,
    email: (state) => state.user.email,
    hash: (state) => state.hash,
    project: (state) => state.project,
    poolData: (state) => state.poolData,
    soloData: (state) => state.soloData,
    basic_option: (state) => state.basic_option,
    ismobile:(state)=>state.ismobile
  },
  mutations: {
    switchLang(state, lang) {
      state.lang = lang
      localStorage.setItem('lang', lang)
    },
    registerEmail(state, email) {
      state.user.email = email
      localStorage.setItem('email', email)
    },
    setHashdata(state, hash) { 
      state.hash = hash
    },
    setProject(state, project) { 
      state.project = project
    },
    setPoolData(state, poolData) { 
      state.poolData = { ...state.poolData, ...poolData }
    },
    setSoloData(state, soloData) { 
      state.soloData = { ...state.soloData, ...soloData }
    },
    setSoloAddress(state, solo_address) { 
      state.solo_address = solo_address
    },
    setMobile(state, mobile_status) { 
      state.ismobile = mobile_status
      },
    setIs_show_notice(state, is_show_notice) { 
      state.is_show_notice = is_show_notice
    }
  },
  actions: {
  },
  modules: {
  }
})

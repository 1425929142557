import axios from 'axios'
import store from '@/store'
import { initHeaders } from '@/settings'
import { getToken } from './auth'
import { checkStatus } from './static'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: '', // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 60000,// request timeout
  // httpsAgent: new https.Agent({  
  //   rejectUnauthorized: false
  // })
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // config.headers['Content-Type'] = 'application/json; charset=UTF-8'
    // config.headers['Accept'] = 'application/json'
    let token = getToken();
    if (token!='') {
      config.headers['Authorization'] = token;
    }
    initHeaders && Object.keys(initHeaders).forEach(key => {
      config.headers[key] = initHeaders[key]
    })
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // console.log("service.interceptors.response",response)
    const {
      config = {}, data: res = {}
    } = response
    const {
      code,
      status,
      message = null,
      msg
    } = res
    const {
      headers = {}
    } = config
    const {
      returnAll = false
    } = headers
    // console.log("returnAll",returnAll)
    const result = (code || code === 0) ? code : status
    if (returnAll) {
      // console.log("request:",returnAll)
      return res
    }
    
    if (result !== 200 && result !== 0) {
		// console.log("5555555》result》》",result)
      // Message({
      //   message: message || msg || 'error',
      //   showClose: true,
      //   type: 'error'
      // })
      return {}
      // return Promise.reject(res.message || msg || 'error')
    } else {
      const {
        data = {}, token = null
      } = res
      let dataSource = null
      if (token) {
        dataSource = {
          token,
          data
        }
      } else {
        dataSource = data
      }
      return dataSource
    }
  },
  error => {
    // console.log("error:>>",error);
    // console.log("error.response",error.response) // for debug
    // return Promise.reject(newErr)
    // return error.response;
    const newErr = checkStatus(error.response)
    // console.log("newErr,status",newErr)
    const {
      status 
    } = newErr
	// console.log("newErr,status",status,newErr)
    if (newErr.data && (status>=400 && status<500)) {
      return newErr.data
    } else if (status) {
      return newErr.data
    } else {
      return Promise.reject(newErr)
    }
  }
)

export default service

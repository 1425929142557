<template>
      <footer class="footer purple fixed " id="footer">
       <!-- <canvas id="footer-dots"></canvas> -->
        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-9 order-r-2">
                <p>&#9400; {{$t('footer.title')}}</p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-3 order-r-1" v-if="$store.state.ismobile">
                <ul>
                  <li>
                    <a class="float-item"  href="https://t.me/zklion" target="_blank">
                      <img src="@/assets/images/telegram-black.png" width="40px;" height="40px">
                    </a>
                 </li>
                  <li>
                     <a class="float-item"  href="https://twitter.com/ZKLion_hk" target="_blank">
                    <img src="@/assets/images/twitter.png" width="40px;" height="40px">
                    </a>
                </li>
                  <li> 
                    <a class="float-item" href="https://github.com/zklion-miner/Aleo-miner" target="_blank"><img src="@/assets/images/github_black.png" width="40px;" height="40px">
                    </a>
                  </li>
                  <!-- <li> 
                    <a class="float-item" href="https://medium.com/@marketing_47582" target="_blank"><img src="@/assets/images/tg.png" width="40px;" height="40px">
                    </a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
	
      </footer>
	
	 <!-- <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
	  <div class="modal-dialog modal-lg modal-dialog-scrollable">
	    <div class="modal-content backdrop-invert">
	      <div class="modal-body">
			<div  v-if="$i18n.locale == 'en'" class="info-content filter box">
				<div class="u-information" style="padding-left: 10px;">
				<h1>INFORMED CONSENT</h1>
				<div class="info-content">
				<p class="bold t-h">SPECIAL NOTICE</p>
				<p>Please read this Informed Consent carefully before you use the services provided by the application.  If there is anything you do not understand or are confused with, please consult a professional lawyer.  If you do not agree to this Informed Consent and/or amendments to it from time to time, please stop using the services provided by the application immediately or cease logging into this application. By logging into the application, using any service of the application or any other similar act, you are deemed to have acknowledged that you are aware of and fully understand this Informed Consent (including any amendment thereto from time to time).
				<span class="bold"> 
				YOU WILL THEN BE BOUND BY THE INFORMED CONSENT AND SHALL NOT INVALIDATE OR CLAIM TO REVOKE THE INFORMED CONSENT ON THE GROUND THAT YOU HAVE NOT READ THE INFORMED CONSENT.</span></p>
				<p class="bold t-h">YOU CONFIRM AND ACKNOWLEDGE HEREBY THAT</p>
				<p>1. You are not on any list of terrorist organizations or terrorists, such as those listed in the UN Security Council Resolutions.</p>
				<p>2. You are not restricted nor prohibited from using the application by any national or regional administrative agency for law enforcement.</p>
				<p>3. You are not a citizen or resident of Cuba, Iran, North Korea, Syria, or any other country or region sanctioned by relevant national governments or international institutions.</p>
				<p>4. You are not a citizen or resident of any country or region that restricts or prohibits cryptocurrency-related activities, including but not limited to the mainland of China.</p>
				<p>5. The use of services provided by the application is permitted under applicable laws, regulations, and policies in your country or region.</p>
				<p class="bold t-h">YOU AGREE THAT YOU WILL ASSUME THE CORRESPONDENT LEGAL RISKS AND OBLIGATIONS INDEPENDENTLY AND YOU HEREBY UNCONDITIONALLY AND IRREVOCABLY WAIVE THE RIGHT OF RECOURSE IF YOUR USE OF THE SERVICES PROVIDED BY THE APPLICATION BECOMES ILLEGAL DUE TO THE LAWS, REGULATIONS, AND POLICIES IN YOUR COUNTRY OR REGION OR ANY OTHER APPLICABLE LAWS.
				</p>
				</div>
				</div>
			</div>
			<div  v-if="$i18n.locale == 'zh'" class="info-content filter box">
				<div class="u-information">
					<h1>用戶知情同意書</h1>
					<div class="info-content">
					<p class="bold t-h">特别提示：</p>
					<p>您在使用本程序提供的各項服務之前，應仔細閱讀本知情同意書，如您有任何不理解或疑惑之處，請咨詢專業律師。如您不同意本知情同意書及鱷或隨時對其的修改，請您立即停止使用本程序提供的服務或不再登錄本程序。您壹旦登錄本程序、使用本程序的任何服務或任何其他類似行為即表示您已了解並完全同意本知情同意書（包括我們對本知情同意書隨時所做的任何修改），
					<span class="bold">本知情同意書即對您產生的約束，屆時您不得以未閱讀本知情同意書的內容等理由，認為本知情同意書無效，或要求撤銷本知情同意書。</span></p>
					<p class="bold t-h">您在此確認並聲明：</p>
					<p>1. 您不在任何恐怖活動組織及恐怖活動人員名單中，如聯合國安理會決議中所列的恐怖活動組織及恐怖活動人員名單。</p>
					<p>2. 您未被任何國家或地區的行政執法機構限制或禁止使用本程序。</p>
					<p>3. 您非古巴、伊朗、朝鮮、敘利亞以及其他受到相關國家政府或國際機構執行制裁的國家或地區居民。</p>
					<p>4. 您非限制或禁止開展數字貨幣相關活動國家或地區的居民，包括但不限於中國大陸地區等。</p>
					<p>5. 您使用本程序提供的服務在您所在的國家或地區符合相關法律法規和政策。</p>
					<p class="bold t-h">您同意：如因您所在國家或地區的法律法規和政策或其他任何適用法律的原因，導致您使用本程序的服務違法，您將獨立承擔相關法律風險和責任，您無條件且不可撤銷地放棄向本程序進行追索的權利。</p></div>
				</div>
			</div>
	      </div>
	     <div class="modal-footer">
			  <div class="footer-box" style="width: 100%;padding: 40px 0 30px">
				  <div class="row" >
					  <div class="col-xl-12 col-lg-12 col-md-12 text-center">
						 <label class="info-know ivu-checkbox-wrapper ivu-checkbox-wrapper-checked ivu-checkbox-default" style="color: #000;font-size: 14px;">
						      <span class="ivu-checkbox ivu-checkbox-checked">
							  <span class="ivu-checkbox-inner"></span> 
							  <input type="checkbox" class="ivu-checkbox-input" v-model="isChecked"></span>
							   {{$t('button.informedConsent')}}
						  </label>
					  </div>
					  <div class="col-xl-12 col-lg-12 col-md-12 text-center" style="margin-top: 15px;">
						  <button type="button" v-if="isChecked" class="btn btn-primary" style="min-width: 150px;    font-size: 14px;" @click="informedContent" data-bs-dismiss="modal">{{$t('button.confirm')}}</button>
						  <button type="button" v-else class="btn btn-outline-secondary" disabled style="min-width: 150px;    font-size: 14px;">{{$t('button.confirm')}}</button>
					 </div>
				  </div>
			  </div>
	      </div>
	    </div>
	  </div>
	</div> -->
</template>

<script>
import { getInformed,setInformed } from '@/utils/auth'
export default {
  name: 'Footer',
  data() {
  	return{
		isChecked:false,
	}
  },
  mounted() {
	  // this.isChecked=getInformed();
	  // if(this.isChecked==false || this.isChecked==''){
		//   console.log("this.isChecked",this.isChecked)
		//  $('#staticBackdrop').modal('show');
	  // }
	  
  },
  methods: {
    // 滚动到页面顶部
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动效果
      });
    },
	informedContent(){
		$('#staticBackdrop').modal('hide');
		setInformed()
	}

  }
}
</script>
<style>
#staticBackdrop{
	z-index: 99999;
}
#staticBackdrop .modal-body {
	padding: 0rem 1rem;
}

.filter.box {
  position: relative;
  /* 裁切多餘區域 */
  overflow: hidden;
  /* 加入 z-index，區塊內的文字才能正確顯示 */
  z-index: 1;
}
.filter.box:after{

    background-color: rgba(0, 0, 0, .25);
    backdrop-filter: invert(80%);
}


.u-information {
	color: #000;
	max-height: 500px;
}
.u-information .t-h {
    padding: 10px 0;
}
.u-information .bold{
	font-weight: 500;
}
.u-information h1 {
	color: #000;
    font-weight: 400;
    font-size: 22px;
    padding: 10px 0 20px;
    text-align: center;
}
.u-information .info-content {
    max-height: 300px;
    overflow-y: auto;
    padding: 0 20px;
}
.u-information p {
    font-size: 16px;
    color: #000;
    line-height: 28px;
    text-align: justify;
}
/* 自定义样式 */
.floating-box {
    position: fixed;
    padding: 10px;
    border-radius: 5px;
    z-index: 9999;
    flex-direction: column;
    bottom: 268px;
    right: 10px;
    z-index: 999;
}
.float-item{
    margin: 45px 0px 0px;
    display: block;
}
.float-item img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 6px 6px rgb(24 8 85 /10%);
}
/*#footer{
  position: fixed;
  width: 100%;
  bottom: 0;
}*/
</style>
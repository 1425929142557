<template>
    <div class="modal fade" id="addMinerGuide" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content bluish-purple">
                <div class="modal-body">
                    <h6 style="padding: 25px;">{{ $t('header.remindAccount') }} </h6>
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('button.cancel') }}</button>
                    <button type="button" class="button" @click="jump">{{ $t('button.confirm') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotMachineDialog',
    data() { },
    methods: {
        jump() { 
            window.location.href='/user/miner'
        }
    }
}
</script>
<style>
@import "./miner/addUserDialog.scss";

li {
    list-style: decimal;
}</style>
import request from '@/utils/request'
import { baseApi } from '@/api/apilist'
import { getBaseToken } from '@/utils/auth'

// 登录 
export function login(data) {
  return request({
    url: `${baseApi}/token-auth`,
    method: 'post',
    data,
    headers: {
      returnAll: true
    }
  })
}

// 获取登陆状态
// /api/v1/token-refresh
// {"token":"ey***"}
//注册
export function getToken() {
  let data = {"token":getBaseToken()}
  return request({
    url: `${baseApi}/token-refresh`,
    method: 'post',
    headers: {
      returnAll: true
    },
    data
  })
}


//注册
export function register(data) {
  return request({
    url: `${baseApi}/account/signup`,
    method: 'post',
    headers: {
      returnAll: true
    },
    data
  })
}

// 重发邮件
export function resendEmail(data) {
  return request({
    url: `${baseApi}/account/resend_activate_email`,
    method: 'post',
    headers: {
      returnAll: true
    },
    data
  })
}

// 忘记密码--获取邮箱验证码
// /api/v1 /secret_code / 
// 参数demo：{email: "***@qq.com"}
export function getCodeByEmail(data) {
  return request({
    url: `${baseApi}/secret_code/send_by_email`,
    method: 'post',
    headers: {
      returnAll: true
    },
    data
  })
}
// 忘记密码--重设密码
//
// /api/v1/account/reset_password
// method post
//参数demo：{"email":"1379542956@qq.com","password":"A12345678","secret_code":"303406"}
export function resetPassword(data) {
  return request({
    url: `${baseApi}/account/reset_password`,
    method: 'post',
    headers: {
      returnAll: true
    },
    data
  })
}

// 修改密码
// 传参：{"old_password":"A123456788","password":"A12345678","confirm":"A12345678"}
// 传参--开启双重验证:{"old_password":"A12345678","password":"A12345678","confirm":"A12345678","mfa_secret_code":"123456"} 
export function changePassword(data) {
  return request({
    url: `${baseApi}/account/change_password`,
    method: 'post',
    headers: {
      returnAll: true
    },
    data
  })
}



// 获取当前用户
// /api/v1/account
export function getCurrentAccount() {
  return request({
    url: `${baseApi}/account`,
    method: 'get'
  })
}


// // 获取用户下的==>  旷工用户列表
///api/v1/miner_account?project=aleo_test3_3
export function getAccounts(projectName) {
  return request({
    url: `${baseApi}/miner_account?project=${projectName}`,
    method: 'get'
  })
}
// 添加用户名
// /api/v1/miner_account
/*
* 参数：
* {"name":"test009","comment":"12121"}
*/
export function createUsername(data) {
  return request({
    url: `${baseApi}/miner_account`,
    method: 'POST',
    data,
    headers: {
      returnAll: true
    }
  })
}

// 用户登录历史
// /api/v1/login_history
/*
* {name: "duanyz", comment: "1"}
*/
export function getLogin_history() {
  return request({
    // url: `${baseApi}/login_history?page=1&page_size=2`,
    url: `${baseApi}/login_history`,
    method: 'get',
  })
}

// 获取MFA信息
//GET /api/v1/account/configure_mfa
export function getConfigure_mfa(data) {
  return request({
    url: `${baseApi}/account/configure_mfa`,
    method: 'get',
  })
}

// 关闭/开启 谷歌认证
// POST /api/v1/account/enable_mfa
// 传参：{"secret_key":"5TDW4GX3NFDVSD4M","verification_code":"460909","secret_code":"330540"}
export function enable_mfa(data) {
  return request({
    url: `${baseApi}/account/enable_mfa`,
    method: 'POST',
    data,
    headers: {
      returnAll: true
    }
  })
}

// 关闭谷歌认证
// POST /api/v1/account/disable_mfa
// 传参：{verification_code: "205435"}
export function disable_mfa(data) {
  return request({
    url: `${baseApi}/account/disable_mfa`,
    method: 'POST',
    data,
    headers: {
      returnAll: true
    }
  })
}

// 开启|关闭：防釣魚碼
// POST api/v1/account/change_fishing
// 开启传参：{"is_enable_fishing_code":true,"fishing_code":"sadsae"}
// 开启传参--双重认证：{is_enable_fishing_code: true, fishing_code: "121", mfa_secret_code: "212121"}
// 关闭传参：{is_enable_fishing_code: false, fishing_code: ""}
// 关闭传参--双重认证：{is_enable_fishing_code: false, fishing_code: "",mfa_secret_code: "212121"}
export function change_fishing(data) {
  return request({
    url: `${baseApi}/account/change_fishing`,
    method: 'POST',
    data,
    headers: {
      returnAll: true
    }
  })
}


// 更新钱包：备注
// patch api/v1/miner_account/${id}
// 开启传参：
// 无双重认证：{name: "test008", comment: "1212"}
export function updateMinerComment(miner_id,data) {
  return request({
    url: `${baseApi}/miner_account/${miner_id}`,
    method: 'patch',
    data,
    headers: {
      returnAll: true
    }
  })
}


// 隐藏|显示 用户
// /api/v1/miner_account/21277
// {name: "test0012", is_visible: false} 隐藏用户
// {"name":"test0012","is_visible":true} 显示用户
// 方法：PUT
export function hiddenAccount(uid,data) {
  return request({
    url: `${baseApi}/api/v1/miner_account/${uid}`,
    method: 'PUT',
    data,
    headers: {
      returnAll: true
    }
  })
}


// 删除用户
// /api/v1/miner_account/21275/delete
export function deleteAccount(uid) {
  return request({
    url: `${baseApi}/api/v1/miner_account/${uid}/delete`,
    method: 'POST',
    data,
    headers: {
      returnAll: true
    }
  })
}

// 批量删除
///api/v1/miner_account/batch_delete
// 参数：{"miner_accounts":[21277,21276]}
export function batchDeleteAccount(data) {
  return request({
    url: `${baseApi}/api/v1/miner_account/batch_delete`,
    method: 'POST',
    data,
    headers: {
      returnAll: true
    }
  })
}






import { createRouter,createWebHistory } from 'vue-router'

import Register from '@/pages/register/index'
// import UserSetting from '@/pages/user/index'
import i18n from '@/i18n/i18n'

const routes = [
    {
      path: '/',
      name: 'index',
      component: ()=> import('@/pages/index/IndexView.vue'),
      meta: {
        title: i18n.global.tm('homePage.title'),
        keyword: i18n.global.tm('homePage.keywords'),
        description:i18n.global.tm('homePage.description')
      }
    },
  
    {
      path: '/login',
      name: 'login',
	  component: ()=> import('@/pages/login/LoginView.vue'),
      // component: LoginView,
      meta: {
        title: i18n.global.tm('loginPage.title'),
        keyword: i18n.global.tm('loginPage.keywords'),
        description:i18n.global.tm('loginPage.description')
      }
    }, 
    {
      path: '/forgot',
      name: 'forgot',
	  component:()=> import('@/pages/forgot/index'),
      // component: Forgot,
      meta: {
        title: i18n.global.tm('forgotPage.title'),
        keyword: i18n.global.tm('forgotPage.keywords'),
        description:i18n.global.tm('forgotPage.description')
      }
  },

   {
    path: '/REVENUE',
    name: 'earnings',
    // component: Earnings,
	component:()=> import('@/pages/miner/earnings/index'),
    meta: {
      title: i18n.global.tm('revenuePage.title'),
      keyword: i18n.global.tm('revenuePage.keywords'),
      description:i18n.global.tm('revenuePage.description')
    }
  
  },
  // {
  //   path: '/user',
  //   name: 'user',
  //   component: UserSetting
  // },
  // ...UserSetting,
  ...Register,
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

// 获取token ，设置token，删除token 
// import { getCurrentInstance } from 'vue'
// const {proxy} = getCurrentInstance()
import Cookies from 'js-cookie'
import { getCurrentInstance } from 'vue'
  
// 创建可以访问内部组件实例的实例
const internalInstance = getCurrentInstance()

const TokenKey = "Token"
const Users = "User"
const MinerAcount = "MinerAcount"
const Project = "project"
const Hash = "hash"
const code = "inviteCode"
const informed = "informed"



// token
export function getInformed() {
  if (localStorage.getItem(informed)) { 
    return localStorage.getItem(informed)
  }
  return ""
}

export function setInformed() {
  return localStorage.setItem(informed,true)
}

export function removeInformed() {
  localStorage.removeItem(informed)
}



// token
export function getinviteCode() {
  // const domain=getDomain()
  if (localStorage.getItem(code)) { 
    return localStorage.getItem(code)
  }
  return ""
}

export function setinviteCode(inviteCode) {
  return localStorage.setItem(code,inviteCode)
}

export function removeinviteCode() {
  localStorage.removeItem(code)
}


// token
export function getToken() {
  // const domain=getDomain()
  if (localStorage.getItem(TokenKey)) { 
    // Cookies.set(Authorization, localStorage.getItem(TokenKey), { domain: domain }, { expires: 7 });
    return "JWT " + localStorage.getItem(TokenKey)
  }
  return ""
}

export function getBaseToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  removeUser()
  removeMinerAcount()
  removeProjectName()
  localStorage.removeItem('email')
  localStorage.removeItem(TokenKey)
}
// User
export function getUser() {
  return localStorage.getItem(Users)
}
export function setUser(user) {
  localStorage.setItem(Users, user)
  // proxy.$cookies.set('k1', 'v1', '1h')
}
export function removeUser() {
  localStorage.removeItem(Users)
}

export function getMinerAcount() {
  return localStorage.getItem(MinerAcount)
}
export function setMinerAcount(miner) {
  localStorage.setItem(MinerAcount, miner)
}
export function removeMinerAcount() {
  localStorage.removeItem(MinerAcount)
}

// project
export function getProjectName() {
  return localStorage.getItem(Project)
}
export function setProjectName(project) {
  localStorage.setItem(Project, project)
}
export function removeProjectName() {
  localStorage.removeItem(Project)
}

function getDomain() { 
  let domain = window.location.hostname;
  let pre = '';
  let hostname = window.location.hostname;
  if (hostname.split('.').length > 1) { 
    pre=hostname.split('.')[0]
    domain=hostname.replace(pre,'')
  }
  // console.log("domain",domain)
  return domain
}
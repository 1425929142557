import main from '@/main'
import { removeToken,removeUser,removeProjectName } from './auth'

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}

export const checkStatus = response => {
  const {
    config = {}, status = 200
  } = response
  const {
    url = '', statusText = '',
      headers: {
        isRedirect = true
      }
  } = config
  
  console.log("static>>返回值>>status>>",status)
  if (status === 401) {
        //   console.log("fullPath:",`/login?redirect=${main.$route.fullPath}`)
          removeToken()
          removeUser()
          removeProjectName()
          window.location.href = `/login`
        //    main.$router.push(`/login?redirect=${main.$route.fullPath}`)
    //   main.$store.dispatch('user/resetToken').then(() => {
    //     main.$router.push(`/login?redirect=${main.$route.fullPath}`)
    //   })
  }
  if (status >= 200 && (status >=400 && status < 500)) {
    // console.log("Static---status",status,response)
    return response
  }
  const errortext = statusText || codeMessage[status]
  if (status !== 401) {
      // console.log(`请求错误 ${status}: ${url}；${errortext}`)
      //alert(`请求错误 ${status}: ${url}；${errortext}`)
    // Message({
    //   message: `请求错误 ${status}: ${url}；${errortext}`,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
  }
  const error = new Error(errortext)
  error.name = status
  error.response = response
  error.status = false
  if (status === 403) {
    main.$router.push(`/403`)
  }
  if (isRedirect) {
      if (status === 401) {
        //   console.log("fullPath:",`/login?redirect=${main.$route.fullPath}`)
          removeToken()
          removeUser()
          removeProjectName()
          window.location.href = `/login`
        //    main.$router.push(`/login?redirect=${main.$route.fullPath}`)
    //   main.$store.dispatch('user/resetToken').then(() => {
    //     main.$router.push(`/login?redirect=${main.$route.fullPath}`)
    //   })
    }
  } else {
    error.status = true
  }
  // return error
  console.log(error)
  return ''
}

import site_en from "./module/site_en"

const en = {
  header: {
    switchLanguage: "Switch Language",
    copyText: 'Copy Success',
    remindAccount: 'Please create a  account to get started',
    language: {
      zh: "zh",
      zhName: "Chinese",
      en: "en",
      enName: "English"
    },
    menu: {
      revenue: "Testnet 3-4 Rewards",
      help: "Tutorial",
      solo: "SOLO"
    },
    userStatus: {
      register: "Register",
      login: "Login",
      // setting: "Account Settings",
      setting: "Settings",
      exit: "Exit"
    },
	ad:{
		title1:'ZKLion officially launches Aleo TestNet Beta version algorithm hoe',
		title2:'Leo Wallet Navigation Tutorial'
	}
  },
  footer: {
    "title": "2024 Cryptorius all Rights Reserved. Designed By ZKLion"
  },

  home: {
    banner: {
      info: 'Optimization of the zkp calculation and Zk-snarks algorithm',
    }
  },
  revenue: {
    title:'Revenue Overview',
    totalIncome: "Total Income",
    totalPayout: "Total Payout",
    yesterdayRevenue: "Yesterday's Earnings",
    yesterdayRevenue_info: "Earnings in the 24 hours before 00:00 UTC of the day.",
	yesterdayPending: "Revenue Pending",
	yesterdayPending_info: "The system will start to calculate and release yesterday's earnings at 0:00 UTC (Beijing time 8:00) every day",
    todayRevenue: "Today Mined (Estimated)",
    todayRevenue_info1: "Current estimated earnings data",
    todayRevenue_info2: "Theoretical reference value calculated according to the PPLNS mode",
    todayRevenue_info3: "Actual earnings may vary.",
	
    creditTitle: "valid credit",
    credit_info: "The Testnet Beta Prover incentive program has been officially opened at 12 noon on July 1, Eastern Time, and will end on July 15 (the specific end time is to be officially announced), and only the points obtained during this period will be considered valid.",
    
    pendingSettlement: "Pending Settlement",
    incomeRecords: "Income Records",
    payoutRecords: "Payout Records",
    export: "Export",
    summary: "Summary",
    income_Records: {
      title: "Income Records",
      title_info:'The revenue of the past 24 hours is settled daily at 00:00 UTC',
      settlement_Time: "Settlement Time",
      settlement_Time_info: "Earnings settlement every day at 00:00 UTC for the past 24 hours",
      amount: "Quantity",
      description: "Remarks",
      desc_default_val: ' revenue',
	  valid_title:'validity',
	  valid:'valid',
	  invalid:'invalid',
    },
    payout_Records: {
      title: "Payout Records",
      Date: "Date",
      Amount: "Quantity",
      Address: "Address",
      Status: "Payment Status",
    },
	epoch_Records: {
	  title: "Epoch",
	  date: "Date",
	  account: "Account",
	  credit: "Credit",
	},
  },
  userState: {
    loginTitle: "Customer Login",
    name: {
      placeholder: 'Please enter a username',
      error: 'username cannot be empty',
      errorFormat: 'The username format is incorrect'
    },
    password: {
      label: 'Password',
      reLabel: "Re-enter Password",
      placeholder: "Enter your Password",
      // placeholder: 'Please enter a password',
      placeholder_repeat: 'Please enter your password again',
      error: 'Password cannot be empty',
      error_num: 'Password length must be between 8-15',
      error_uppercase: 'The password must contain one uppercase letter and one number'
    },
    email: {
      label: 'Email address',
      placeholder: "Email Address",
      // placeholder: 'Please enter an email',
      check: 'Email Verification',
      error: 'Email cannot be empty',
      errorFormat: 'The email format is incorrect'
    },
    referralCode: {
      label:'Refferal Code (optional)',
      placeholder: 'Please enter the Refferal Code',
      error: 'Refferal Code Error',
    },
    code: {
      placeholder: 'Please enter the verification code',
      getCode: "Send code",
      error: 'Verification code cannot be empty',
      resendRemind: 'seconds resend'
    },
    mfa: {
      title: "Google VERIFICATION",
      label: "MFA",
      placeholder: "Please enter your MFA code",
      remind: 'Google Auth device lost?'
    },
    mfaInfo: {
      title1: "If you have backed up the 16-character security key of your google authenticator, please skip this part and use the key to retrieve it quickly.",
      title2: "If you have lost your security key. please contact us via Telegrame or send an email to"
    },
    agreenProtocol: {
      label: "I have read and agreed to the ZKLion",
      Protocol: "Terms of Service",
      error: "Please read and agree to the service terms first."
    },
    resendEmail: {
      title: 'AN ACTIVATION EMAIL HAS BEEN SUCCESSFULLY SENT TO THE EMAIL ADDRESS',
      lebel: 'If you do not receive the email,please try the following methods',
      item1: '1、Please make sure you enter the correct email address.',
      item2: '2、Please check your spam folder.',
      item3: '3、Add ZKLion to your email address whitelist.',
      item4: '4、Resend the email.',
      item5: '5、Contact us '
    },
    register: 'Register',
    registerText: 'Register',
    login: 'Login',
    reset: 'Reset password',
    forgot: 'Forgot password',
    forgotText: "Forgot your password?",
    noAccountText: "Don't have an account?",
    createAccountText: 'Create New Account',
    haveAccountText: "Already have an account with us",
  },
  valid: {
    empty: "This field cannot be empty.",
    must_6_charts: "Ensure this field has at least 6 characters",
    username_check: "Must start with a letter and have 5-15 characters",
    mfa: {
      empty: 'Google auth cannot be empty'
    },
    password: {
      error: 'Password cannot be empty',
      error_num: 'Password length must be between 8-15',
      error_uppercase: 'The password must contain one uppercase letter and one number'
    },
    email: {
      error: 'Email cannot be empty',
      errorFormat: 'The email format is incorrect'
    },
    address: {
      error: 'Aleo Address cannot be empty',
      errorFormat: 'The aleo address format is incorrect'
    }
  },
  userSetting: {
    "title": 'User Setting',
    "Account": {
      "item1": {
        "title": " Account",
        "info": ""
      },
      "item1-1": {
        "title": "Create  Account",
        "info": ""
      },
      "item1-2": {
        "title": "username",
        "info": ""
      },
      "item1-3": {
        "title": "Please input your username",
        "info": ""
      },
      "item1-4": {
        "title": "Please input your note",
        "info": ""
      },
      "item2": {
        "title": "Note",
        "info": ""
      },
      "item3": {
        "title": "Address",
        "info": ""
      },
      "item4": {
        "mainTitle": 'Actions',
        "title": "Payout Settings",
        "info": ""
      },

      "item5-1": {
        "title": "address",
        "info": ""
      },
      "item5": {
        "title": "Edit Note",
        "info": ""
      },
      "item6": {
        "title": "Edit Address",
        "info": ""
      },
      "item7": {
        "title": "Auto Pay",
        "info": ""
      },
      "item8": {
        "title": "Sync Address",
        "info": ""
      },
      "item9": {
        "title": "Edit Address",
        "info": ""
      },
      "item10": {
        "title": "Please enter your address of Aleo",
        "info": ""
      },
      "item11": {
        "title": "Current Payout Threshold",
        "info": ""
      },
      "item12": {
        "title": "Enable Payment",
        "info": ""
      },
      "item13": {
        "title": "Suspend Payment",
        "info": ""
      },
      "item14": {
        "title": "Payout Threshold Setting",
        "info": ""
      },
      "item15": {
        "title": "Change  Account Aa's Payment Threshold of Aleo To",
        "info": ""
      },
      "item16": {
        "title": "Please check the email sent to you and click the confrmation link in the email to activate your new address within 24 hours. Payouts wil be paused untl activaton.",
        "info": ""
      },
      "item16-1": {
        "title": "Your address change has not been activated yet",
        "info": ""
      },
      "item17": {
        "title": 'Please enable Two-Factor Authentication to get started'
      }
    },
    "securitySetting": {
      "state": {
        "seted": 'Already Set',
        "opened": 'Opened',
        "closed": 'Closed',
        "open": 'Setup',
        "close": 'Close'
      },
      "item1": {
        "title": "Security Settings",
        "info": ""
      },
      "item2": {
        "title": "Password Login",
        "info": "Secure your account"
      },
      "item3": {
        "title": "Already Set",
        "info": ""
      },
      "item4": {
        "title": "Change",
        "info": ""
      },
      "item4-1": {
        "title": "Current Password",
        "info": ""
      },
      "item4-2": {
        "title": "New Password",
        "info": ""
      },
      "item4-3": {
        "title": "Re-enter New Password",
        "info": ""
      },
      "item4-4": {
        "title": "Dual authentication dynamic password",
        "info": ""
      },
      "item5": {
        "title": "Two-Factor Authentication",
        "info": "Used to login to the account, add or change the payout address."
      },
      "item5-1": {
        // "title":"For The Security Reasons, Please Connect To Your Google Account.",
        "step": "Step 1",
        "step2": "Step 2",
        "Previous": "Previous",
        "next": "Next",
        "title": "Turn on Two-Factor Authentication ",
        "info": ""
      },
      "item5-2": {
        // "title":"Download And Install Google Authenticator",
        "title": "Please use Google Authenticator (or other compatible applications) on your phone to scan the following QR code, or manually input the 16 characters key password.",
        "info": ""
      },
      "item5-3": {
        // "title":"Open Google Authenticator And Scan The QR Code",
        "title": "Please save this key properly to avoid being stolen or lost. In case of loss of your 2FA device, you can use this key to recover it. If the key is lost, you need to submit the reset request to our customer service team. The processing time is 7 days.",
        "info": ""
      },
      "item5-4": {
        "title": "If you fail to scan the QR code, you can enter the 16-digits secret key into Google Authenticator.",
        "info": ""
      },
      "item5-5": {
        "title": "Secret Key",
        "info": ""
      },
      "item5-6": {
        "title": "Copy",
        "info": ""
      },
      "item5-7": {
        "title": "Backup Secret Key",
        "info": ""
      },
      "item5-8": {
        "title": "The secret key is used to recover your Google authentication, please keep the 16-digits secret key in a safe place.",
        "info": ""
      },
      "item5-9": {
        "title": "Add Google Authenticator",
        "info": ""
      },
      "item5-10": {
        "title": "Email Verification",
        "info": ""
      },
      "item5-11": {
        "title": "Please enter your Email Verification",
        "info": ""
      },
      "item5-12": {
        "title": "Get Verification Code",
        "info": ""
      },
      "item5-13": {
        "title": "Two-Factor Authentication",
        "info": ""
      },
      "item5-14": {
        "title": "Confirm",
        "info": ""
      },
      "item6": {
        "title": "Anti-Phishing Code",
        "info": "When turned on, all genuine emails from ZKLion will display an anti-phishing code to prevent phishing scams"
      },
      "item6-1": {
        "title": "Your Anti-Phishing Code",
        "info": ""
      },
      "item6-2": {
        "title": "Please enter your Anti-Phishing Code",
        "info": ""
      },
      "item6-3": {
        "title": "Confirm",
        "info": ""
      }
    },
    "personalCenter": {
      'title': 'personal Center',
      "item1": {
        "title": "Username",
        "info": ""
      },
      "item2": {
        "title": "Login History",
        "info": ""
      },
      "item3": {
        "title": "Time",
        "info": ""
      },
      "item4-1": {
        "title": "Country",
        "info": ""
      },
      "item4": {
        "title": "Location",
        "info": ""
      },
      "item5": {
        "title": "Equitment",
        "info": ""
      },
      "item6": {
        "title": "page",
        "info": ""
      }
    }
  },
  button: {
    return: "Go Back",
    resend_email: 'Resend Email',
    reset_pwd:"Reset Password",
    confirm: "Confirm",
    cancel:'Cancel',
    add: 'Add',
    move: 'Move Worker',
    del:'Delete Worker',
    cancel_change:"Cancel Change",
	informedConsent: "Got it",
  },
  news: {
    "announcement": 'Announcement',
  },
  ...site_en,
  tableLocale: 'en',
  pagination:{ 
    perpagesize: {
      left: '',
      right: 'Show rows',
      total: 'Total ',
      record:' Records'
    }
  }
}


export default en

import "bootstrap/dist/css/bootstrap.css" 
import "./assets/css/animations.css"
import "./assets/css/style.css"
import "./assets/css/color.css"
import "./assets/css/responsive.css"
import './assets/css/mobile.scss'
import './assets/font/iconfont.css'
import "bootstrap/dist/js/bootstrap.js"


import './permission' // permission control

import { createApp } from 'vue'
import Cookies from 'js-cookie';

import App from './App.vue'
import i18n from './i18n/i18n.js'
import router from './router'
import store from './store'
import { Pagination } from 'vue-amazing-ui'



const app = createApp(App)
app.config.globalProperties.$cookies = Cookies;
app.use(store);
app.use(router);
app.use(i18n);
app.use(Pagination, {
  lang: 'en'
});



app.mount("#app");




import request from '@/utils/request'
import { aleoBaseApi,aleoBaseApi3 } from './apilist'


/* sh 的接口  IP 读取是否是中国--- */
// http://172.30.56.104:5210/api/v1/aleo/ip/forbidden
export function checkChinaMainland() {
    return request({
        url: `${aleoBaseApi}/aleo/ip/forbidden`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}


/* sh 的接口 ---读取算力图数据*/
export function getAleoHashSpeed() {
    return request({
        url: `${aleoBaseApi3}/pool/speed/chart`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}

/* sh 的接口 ---废弃接口*/
export function homeNetwork() {
    return request({
        url: `${aleoBaseApi}/network`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}

// 消息通知
export function getNotice() {
    return request({
        url: `${aleoBaseApi}/notice/get_news`,
        // headers: {
        //     'locale': localStorage.getItem('lang'), // 根据实际需要设置请求
        //     'aa':'12121'
        // },
        method: 'get',
        headers: {
            'locale': localStorage.getItem('lang')?localStorage.getItem('lang'):'zh', 
            returnAll: true
        }
    })
}

// 获取solo 详情信息
export function getAleoDetail(address) { 
    return request({
        url: `${aleoBaseApi}/solo/info/${address}`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}

// 获取solo 详情信息
/* 传参：
{
    "addr":"aleo1666y6x0qcxa7syyahys6tzalp3aqppqwj7tdf6purwtlyjkpwsxs3wtxlp",
    "page":1,
    "page_size":10
}*/
export function getAleoSolutions(data) { 
    return request({
        url: `${aleoBaseApi}/solo/solutions`,
        method: 'post',
        data,
        headers: {
            returnAll: true
        }
    })
}


// 获取solo 首页 solo总算力图详情信息
export function getHomeAleopowerChart(address) { 
    return request({
        url: `${aleoBaseApi}/solo/power/chart`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}


// 获取solo 首页 全网算力图详情信息
export function getHomeNetworkpowerChart(address) { 
    return request({
        url: `${aleoBaseApi}/solo/network/power/chart`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}

// /api/v1/solo/network/overview

// 获取solo 首页 全网算力图详情信息
export function getHomeNetworkOverview(address) { 
    return request({
        url: `${aleoBaseApi}/solo/network/overview`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}

// http://172.30.56.64:5200/api/v1/solo/price/chart
export function getHomeSoloPriceChart(address) { 
    return request({
        url: `${aleoBaseApi}/solo/price/chart`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}

/*
solo详情页算力、收益图表：
3小时：
http://172.30.56.64:5200/api/v1/solo/powerandreward/chart/aleo105e586rlgfp9uf75s9lvzzefraehcs0kej3ddftvffvchc2k5qgqwu3rx5?range=3h
24小时：
http://172.30.56.64:5200/api/v1/solo/powerandreward/chart/aleo105e586rlgfp9uf75s9lvzzefraehcs0kej3ddftvffvchc2k5qgqwu3rx5?range=24h
*/
export function getHomeSoloRewardChart(address,timeRange) { 
    return request({
        url: `${aleoBaseApi}/solo/powerandreward/chart/${address}?range=${timeRange}`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}


/* http://172.30.56.64:5200/api/v3/pool/code_exist/:code
----验证用户激活码
*/
export function checkCode(code) { 
    return request({
        url: `${aleoBaseApi3}/pool/code_exist/${code}`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}

/*POST: http://172.30.56.64:5200/api/v3/pool/register
传参：
{
"email":"",
"code":""
}
*/
export function registerUser(data) { 
    return request({
        url: `${aleoBaseApi3}/pool/register`,
        method: 'post',
        data,
        headers: {
            returnAll: true
        }
    })
}

/*
创建Account 调用接口
POST: http://172.30.56.64:5200/api/v3/pool/account/create
传参：
{
"email":"",
"user_id":"",
"account_id":""
}
*/

export function createAccount(data) { 
    return request({
        url: `${aleoBaseApi3}/pool/account/create`,
        method: 'post',
        data,
        headers: {
            returnAll: true
        }
    })
}


export function getMachineGpu(data) { 
    return request({
        url: `${aleoBaseApi}/solo/machine/gpu`,
        method: 'post',
        data,
        headers: {
            returnAll: true
        }
    })
}
import router from './router'
import { getToken } from './utils/auth'
import NProgress from 'nprogress' // progress bar
const whiteList = ['/login', '/news','/index','/','/solo','/solo/*', '/signUp','/distributor/export', '/forgot','/service','/send-email'] // no redirect whitelist
const loginPathList = ['/login'] // no redirect whitelist

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

router.beforeEach(async (to, from, next) => { 
    // 给界面+标题
    if (to.meta.title) { 
        document.title = to.meta.title;
        if (document.querySelector('meta[name="keywords"]')) {
            document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords)
        } 
        if (document.querySelector('meta[name="description"]')) {
            document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
        } 
    }
    const pattern = /\/solo\//;
    if (whiteList.includes(to.path) || whiteList.indexOf(to.path) !== -1 || pattern.test(to.path)) {
        next()
        NProgress.done()
    }
    
    const hasToken = getToken()
    if (hasToken) {
        const {
            path = ''
        } = to

        if (loginPathList.includes(path)) {
            next({
                path: '/'
            })
            NProgress.done()
        } else { 
            next()
            NProgress.done()
        }
    } else { 
        const {
            search = ''
        } = location
        const redirect = search && search !== '' ? search : `?redirect=${to.path}`
        next(`/login${redirect}`)
        NProgress.done()
    }
})

router.afterEach(() => {
  // finish progress bar
    NProgress.done()
})